<template> 
<div class="productpage">
  <div class="productbox">
    <h1 class="product-title">Dieptereiniging</h1>
    <p class="product-subtitle">{{data.Dieptereiniging}}</p>
  </div>
    <v-container fluid class="container">
      <v-row>
        <v-col v-for="entry in entries" :key="entry._id">
          <v-card
          :loading="loading"
          class="mx-auto"
          max-width="374"
          >
            <v-img height="250" :src="'https://login-beautysalon.nl/' + entry.Image.path"></v-img>

            <v-card-title>{{entry.Title}}</v-card-title>

            <v-card-text>
              <div class="subtitle-1 black--text">
                € {{entry.Price}}
              </div>

              <div>{{entry.Text}}</div>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
  import axios from 'axios';
  const url="https://login-beautysalon.nl/api/collections/get/dieptereiniging?token=749159b39575cd13e793ad25fd9146";
  const url1="https://login-beautysalon.nl/api/singletons/get/General?token=749159b39575cd13e793ad25fd9146";

export default {
  data (){
    return {
      data: [],
      entries: [],
      id: this.$route.params.id
    }
},
  created () {
    axios
    .get(url)
    .then(collection => {
      this.entries = collection.data.entries
      console.log(this.entries)
    })
    .catch(error => {
      console.log(error);
    })

    axios
    .get(url1)
    .then(singleton => {
      this.data = singleton.data
    //   console.log(this.data)
    })
    .catch(error => {
      console.log(error);
    })
  }
}
</script>

<style lang="scss">
@import "@/css/style.scss"
</style>