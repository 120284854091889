<template>
    <main>
        <agile :options="options">
            <div class="slide" v-for="slider in data.Gallery" :key="slider._id">
                <img style="width:100%;" :src="'https://login-beautysalon.nl/' + slider.path">
            </div>
        </agile>

      <div class="over">
      <h1>OVER MIJ</h1>
      <p style="width:75%;" class="textover">
          {{data.AboutMe}}
      </p>
      <div class="foto">
          <img class="pic" :src="'https://login-beautysalon.nl/' + data.Image.path">
          </img>
      </div>
      </div>
    </main>

</template>
<script>
import axios from 'axios';
  const url="https://login-beautysalon.nl/api/singletons/get/About?token=749159b39575cd13e793ad25fd9146";
  
export default {
   data () {
    return {
        data: [],
        options: {
            arrows: false,
            
            responsive: [
                {
                    breakpoint: 0,
                    settings: {
                      navButtons: false,  
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                      navButtons: false,  
                      autoplay: true,
                      autoplaySpeed: 5000,
                      fade: true,
                      changeDelay: 3000,
                      arrows: false,
                      dots: false,
                      infinite: true,
                      pauseOnHover: false,
                    }
                },
                
                {
                    breakpoint: 900,
                    settings: {
                        autoplay: true,
                        autoplaySpeed: 5000,
                        dots: true,
                        infinite: true,
                        navButtons: false,  
                        pauseOnHover: true,        
                    }
                }
            ]
        }
    }
},

created () {
    axios
    .get(url)
    .then(singleton => {
      this.data = singleton.data
    //   console.log(this.data)
    })
    .catch(error => {
      console.log(error);
    })
}
}

</script>

<style lang="scss">
@import "@/css/style.scss"
</style>
