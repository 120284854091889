<template>
<div>
    <Face />
    <Losse />
    <Lash />
    <Harsen />
    <GelPolish />
    <GelNagels />
    <Pedicure />
    
    
</div>



</template>

<script>
  import Face from '@/components/Face';
  import Harsen from '@/components/Harsen';
  import Lash from '@/components/Lash';
  import Pedicure from '@/components/Pedicure';
  import Losse from '@/components/Losse';
  import GelPolish from '@/components/GelPolish';
  import GelNagels from '@/components/GelNagels';

  


export default {
    components: {
      Face,
      Harsen,
      Lash,
      Pedicure,
      Losse,
      GelPolish,
      GelNagels
  },
}
</script>
