<template>
  <div class="pricelistpage">

  <!-- Gezichtsbehandelingen -->

  <div class="productbox">
    <h1 class="product-title">Gezichtsbehandelingen</h1>
    <p class="product-subtitle">{{data.Gezichtsbehandelingen}}</p>
  </div>
    <v-container fluid>
      <v-row>
        <v-col v-for="entry in entries" :key="entry._id">
          <v-card color="#728d69"  class="white--text mx-auto" max-width="344" min-width="300">
            <v-card-title> 
              {{entry.Title}}
            </v-card-title>
            <v-card-subtitle class="white--text">({{entry.Time}})</v-card-subtitle>
            <v-card-text class="white--text" >
              <h7 v-for="tag in entry.Tags">{{tag}} - </h7>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>€ {{entry.Price}}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</div> 
</template>

<script>
  import axios from 'axios';
  const url="https://login-beautysalon.nl/api/collections/get/GezichtsBehandelingen?token=749159b39575cd13e793ad25fd9146";
  const url1="https://login-beautysalon.nl/api/singletons/get/General?token=749159b39575cd13e793ad25fd9146";


export default {

  data (){
    return {
      data: [],
      entries: [],
      id: this.$route.params.id
    }
},
  created () {
    axios
    .get(url)
    .then(collection => {
      this.entries = collection.data.entries
      // console.log(this.entries)
    })
    .catch(error => {
      console.log(error);
    })

    axios
    .get(url1)
    .then(singleton => {
      this.data = singleton.data
    //   console.log(this.data)
    })
    .catch(error => {
      console.log(error);
    })
  }
}
</script>

<style lang="scss">
@import "@/css/style.scss"
</style>