<template>
<div>
    <Reiniging />
    <Lotion />
    <Dieptereiniging />
    <Masker />
    <Cremes />
    <Voetverzorging />

    
</div>



</template>

<script>
  import Reiniging from '@/components/Reiniging';
  import Lotion from '@/components/Lotion';
  import Dieptereiniging from '@/components/Dieptereiniging';
  import Masker from '@/components/Masker';
  import Cremes from '@/components/Cremes';
  import Voetverzorging from '@/components/Voetverzorging';


export default {
    components: {
      Reiniging,
      Lotion,
      Dieptereiniging,
      Masker,
      Cremes,
      Voetverzorging
  },
}
</script>
